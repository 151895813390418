import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  gap: 0.5rem;
  color: rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  transition: all 0.2s ease-in-out;
  border: none;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    transform: translateY(-1px);
    box-shadow: var(--box-shadow--subtle);
  }

  ${(props) => {
    switch (props.$variant) {
      default:
        return `
                    background-color: var(--background--inactive);
                `;
      case "secondary":
        return `
                    background-color: #ffffff;
                `;
    }
  }}
`;

const Button = ({ $variant, label, icon }) => {
  return <StyledButton $variant={$variant}>{label}</StyledButton>;
};

export default Button;

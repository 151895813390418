import React, { useState } from "react";
import styled from "styled-components";
import TabGroup from "../Tabs/TabGroup";
import PreviewBar from "./PreviewBar";
import ComposerForm from "./ComposerForm";
import { createOrUpdateItem } from "../../actions/Pages";
import { getType, getBasicInfo } from "../../actions/Items";

const CardLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 0.5rem 1.25rem 0 0.5rem;
  overflow: hidden;

  background-color: #ffffff;
  border-radius: 1rem 1rem 0 0;
  box-shadow: var(--box-shadow--default);

  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: calc(800px - 2rem + 6px);
  z-index: 1000;
`;

const Composer = ({ idToken, pageId, onItemAdded, onItemUpdated }) => {
  const [activeTab, setActiveTab] = useState("link");

  const handleSubmit = async (text) => {
    if (text.trim()) {
      try {
        // Create the initial item
        const newItem = await createOrUpdateItem(idToken, pageId, null, {
          text
        });

        // Add the new item to the list
        onItemAdded({ ...newItem, status: "success" });

        // Get the type for the new item
        const typeData = await getType(text);

        if (typeData && newItem.id) {
          // Combine the original item data with the type data
          const updatedItemData = { ...newItem, ...typeData };

          // Update the item with type data using the same itemId
          const savedItem = await createOrUpdateItem(
            idToken,
            pageId,
            newItem.id,
            updatedItemData
          );

          // Update the item in the UI
          onItemUpdated({ ...savedItem, status: "success" }, newItem.id);
        }

        const basicInfoData = await getBasicInfo(typeData.type, text);

        if (basicInfoData && newItem.id) {
          // Combine the original item data with the type data
          const updatedItemData = { ...newItem, ...basicInfoData };

          // Update the item with type data using the same itemId
          const savedItem = await createOrUpdateItem(
            idToken,
            pageId,
            newItem.id,
            updatedItemData
          );

          // Update the item in the UI
          onItemUpdated({ ...savedItem, status: "success" }, newItem.id);
        }
      } catch (error) {
        console.error("Error creating or updating item:", error);
        // Notify the parent component about the error
        onItemAdded({ text, status: "error" });
      }
    }
  };

  return (
    <CardLayout>
      <TabGroup activeTab={activeTab} click={setActiveTab} />
      <ComposerForm activeTab={activeTab} onSubmit={handleSubmit} />
      <PreviewBar $isEmpty={true} items={[]} />
    </CardLayout>
  );
};

export default Composer;

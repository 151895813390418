import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function Index({ user, pages, createNewPage, deletePage, onLogout, idToken }) {
  const [pageToDelete, setPageToDelete] = useState(null);

  useEffect(() => {
    document.title = "Page Builder";
  }, []);

  const handleDeleteClick = (pageId) => {
    setPageToDelete(pageId);
  };

  const confirmDelete = () => {
    if (pageToDelete) {
      deletePage(pageToDelete);
      setPageToDelete(null);
    }
  };

  const cancelDelete = () => {
    setPageToDelete(null);
  };

  return (
    <>
      <h1>Welcome, {user.displayName}</h1>
      <button onClick={onLogout}>Logout</button>
      <button onClick={createNewPage}>Create New Page</button>

      <h2>Your Pages</h2>
      {pages.length === 0 ? (
        <p>You don't have any pages yet.</p>
      ) : (
        <ul>
          {pages.map((page) => (
            <li key={page.id}>
              <h3>
                <Link to={`/page/${page.id}?edit=true`}>{page.title}</Link>
              </h3>
              {pageToDelete === page.id ? (
                <>
                  <p>Are you sure you want to delete this page?</p>
                  <button onClick={confirmDelete}>Yes, delete</button>
                  <button onClick={cancelDelete}>Cancel</button>
                </>
              ) : (
                <button onClick={() => handleDeleteClick(page.id)}>
                  Delete
                </button>
              )}
            </li>
          ))}
        </ul>
      )}
    </>
  );
}

export default Index;

import React from "react";
import styled from "styled-components";

const Debug = styled.pre`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  margin: 0;
  white-space: pre-wrap;
`;

const DebugRow = styled.div`
  display: flex;
`;

const Label = styled.span`
  font-weight: bold;
  opacity: 0.5;
  width: 10rem;
  flex-shrink: 0;
  word-break: break-all;
`;

const Value = styled.span`
  font-weight: normal;
  flex-grow: 1;
  overflow: hidden;
  white-space: pre-wrap;
  word-break: break-all;
`;

function DebugSection({ item }) {
  return (
    <Debug>
      <DebugRow>
        <Label>type</Label>
        <Value>{item.type}</Value>
      </DebugRow>
      {Object.entries(item.basicInfo).map(([label, value]) => (
        <DebugRow key={label}>
          <Label>{label}</Label>
          <Value>
            {typeof value === "object" ? JSON.stringify(value) : value}
          </Value>
        </DebugRow>
      ))}
    </Debug>
  );
}

export default DebugSection;

import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";

const Button = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;

  padding: 8px 11px 8px 10px;
  border-radius: 0.5rem;
  border: none;

  font-size: 1rem;
  font-weight: 600;

  transition: all 0.2s ease-in-out;

  ${(props) => {
    switch (props.$isActive) {
      case true:
        return `
                    background-color: var(--background--active);
                    color: #ffffff;
                    `;
      default:
        return `
                    background-color: var(--background--inactive);
                    color: rgba(0, 0, 0, .5);
                    cursor: pointer;

                    &:hover {
                        box-shadow: var(--box-shadow--subtle);
                        transform: translateY(-1px);
                    }
                `;
    }
  }}
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  width: 0.875rem;
  height: 0.875rem;
  padding: 0.125rem;
`;

const TabButton = ({ label, icon, $isActive, clickHandler }) => {
  return (
    <Button $isActive={$isActive} onClick={clickHandler}>
      <StyledFontAwesomeIcon icon={fas[icon]} />
      {label}
    </Button>
  );
};

export default TabButton;

import React from "react";
import styled from "styled-components";

const Card = styled.div`
  // Add your styling here
`;

function RecipeCard({ item }) {
  return (
    <Card>
      <h3>Recipe: {item.text}</h3>
      {/* Add more recipe-specific details here */}
    </Card>
  );
}

export default RecipeCard;

import React from "react";
import styled from "styled-components";

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 0.5rem;

  padding: 0.5rem 1.25rem;
  margin-left: -1.25rem;

  background-color: var(--background--button-primary);

  color: var(--text--label);
  font-size: 0.875rem;

  ${(props) => {
    switch (props.$isEmpty) {
      case true:
        return `
                    justify-content: right;
                `;
      default:
        return `
                    justify-content: left;
                `;
    }
  }}
`;

const TabAffordance = styled.span`
  padding: 4px;
  border-radius: 3px;
  border: 1px solid var(--text--label);
  margin-left: 0.2rem;

  text-transform: uppercase;
  font-size: 0.6rem;
  font-weight: 700;
  color: var(--text--label);
`;

const PreviewBar = ({ $isEmpty, children }) => {
  return (
    <PreviewContainer $isEmpty={$isEmpty}>
      {/* {{children}} */}
      {$isEmpty ? (
        <p>
          Generate previews <TabAffordance>tab</TabAffordance>
        </p>
      ) : (
        children
      )}
    </PreviewContainer>
  );
};

export default PreviewBar;

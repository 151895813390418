import React from "react";
import styled from "styled-components";

const Card = styled.div`
  // Add your styling here
`;

function BookCard({ item }) {
  return (
    <Card>
      <h3>Book: {item.text}</h3>
      {/* Add more book-specific details here */}
    </Card>
  );
}

export default BookCard;

import React, { useState } from "react";
import styled from "styled-components";
import Button from "../Button";

const Form = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  padding: 0.5rem 0;
`;

const TextInput = styled.input`
  flex: 1;
  border: none;
  background: none;
  padding: 12px;
  border-radius: 0.5rem;

  font-size: 1.2rem;
  font-weight: 600;

  &:focus {
    outline: var(--outline--focus);
  }
`;

const TextArea = styled.textarea`
  flex: 1;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  resize: vertical;

  &:focus {
    outline: 2px solid var(--background--button-primary);
  }
`;

const FileInput = styled.input`
  width: 100%;
`;

const ComposerForm = ({ activeTab, onSubmit }) => {
  const [inputText, setInputText] = useState("");

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setInputText("");
    if (inputText.trim()) {
      try {
        await onSubmit(inputText.trim());
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const renderFormContent = () => {
    switch (activeTab) {
      case "link":
        return (
          <Form onSubmit={handleSubmit}>
            <TextInput
              type="text"
              value={inputText}
              onChange={handleInputChange}
              placeholder="Search for something..."
            />
            <Button
              variant="primary"
              label="Save"
              icon="return"
              type="submit"
            />
          </Form>
        );
      case "text":
        return (
          <Form onSubmit={handleSubmit}>
            <TextArea
              value={inputText}
              onChange={handleInputChange}
              placeholder="Enter your text here..."
              rows={4}
            />
            <Button
              variant="primary"
              label="Save"
              icon="return"
              type="submit"
            />
          </Form>
        );
      case "upload":
        return (
          <Form onSubmit={handleSubmit}>
            <FileInput type="file" onChange={handleInputChange} />
          </Form>
        );
      default:
        return null;
    }
  };

  return renderFormContent();

  //   return (
  //     <form onSubmit={handleSubmit}>
  //       <input
  //         type='text'
  //         value={inputText}
  //         onChange={(e) => setInputText(e.target.value)}
  //         placeholder='Add a new item'
  //       />
  //       <button type='submit' disabled={!inputText.trim() || isSubmitting}>
  //         {isSubmitting ? 'Adding...' : 'Add Item'}
  //       </button>
  //     </form>
  //   );
};

export default ComposerForm;

import React from "react";
import styled from "styled-components";

const Card = styled.div`
  // Add your styling here
`;

function DefaultCard({ item }) {
  return (
    <Card>
      <h3>{item.text}</h3>
      {/* Add generic details here */}
    </Card>
  );
}

export default DefaultCard;

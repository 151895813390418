import { API_URL, FCC_AUTH } from "../config";

export const getType = async (text) => {
  try {
    const encodedText = encodeURIComponent(text);
    const response = await fetch(`${API_URL}/parsers/type?url=${encodedText}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    });

    if (!response.ok) {
      throw new Error("Failed to get type");
    }

    return await response.json();
  } catch (error) {
    console.error("Error getting type:", error);
    return null;
  }
};

export const getBasicInfo = async (type, url) => {
  const response = await fetch(
    `${API_URL}/parsers/basic?type=${type}&url=${url}`,
    {
      method: "GET"
    }
  );

  const data = await response.json();

  return data;
};

export const getPremiumInfo = async (url) => {
  const response = await fetch(
    `${API_URL}/scrapers/premium?url=${url}&fcc_auth=${FCC_AUTH}`,
    {
      method: "GET"
    }
  );

  const data = await response.json();

  return data;
};

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import Index from "./views/Index";
import Page from "./views/Page";

import { onAuthStateChanged, getIdToken } from "firebase/auth";
import { auth } from "./firebase";
import { Auth, handleLogout } from "./components/Auth";
import { createNewPage, fetchPages, deletePage } from "./actions/Pages";
import { ThemeProvider } from "styled-components";
import { GlobalStyles, theme } from "./globalStyles";

const Container = styled.div`
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
`;

function App() {
  const [user, setUser] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [pages, setPages] = useState([]);

  useEffect(() => {
    if (idToken) {
      fetchPages(idToken, setPages);
    }
  }, [idToken]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      if (user) {
        getIdToken(user).then((token) => {
          setIdToken(token);
        });
      } else {
        setIdToken(null);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Container>
        <Router>
          <Routes>
            {user ? (
              <Route
                path="/"
                element={
                  <Index
                    user={user}
                    pages={pages}
                    createNewPage={() =>
                      createNewPage(idToken, fetchPages, setPages)
                    }
                    deletePage={(pageId) =>
                      deletePage(idToken, pageId, fetchPages, setPages)
                    }
                    onLogout={handleLogout}
                  />
                }
              />
            ) : (
              <Route
                path="/"
                element={<Auth onLogout={handleLogout} setUser={setUser} />}
              />
            )}
            <Route
              path="/page/:pageId"
              element={<Page idToken={idToken} user={user} />}
            />
          </Routes>
        </Router>
      </Container>
    </ThemeProvider>
  );
}

export default App;

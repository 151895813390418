import { API_URL } from "../config";

export const createNewPage = async (idToken, fetchPages, setPages) => {
  if (!idToken) {
    console.error("No ID token available");
    return;
  }

  try {
    const response = await fetch(`${API_URL}/pages`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        title: new Date().toISOString(),
        content: []
      })
    });

    if (!response.ok) {
      throw new Error("Failed to create new page");
    }

    await fetchPages(idToken, setPages);
  } catch (error) {
    console.error("Error creating new page:", error);
  }
};

export const fetchPages = async (idToken, setPages) => {
  try {
    const response = await fetch(`${API_URL}/pages`, {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    });

    if (!response.ok) {
      throw new Error("Failed to fetch pages");
    }

    const data = await response.json();
    setPages(data);
  } catch (error) {
    console.error("Error fetching pages:", error);
  }
};

export const fetchPage = async (idToken, pageId) => {
  try {
    const response = await fetch(`${API_URL}/pages/${pageId}`, {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    });

    if (!response.ok) {
      throw new Error("Failed to fetch page");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching page:", error);
  }
};

export const updatePageMetadata = async (idToken, pageId, updatedMetadata) => {
  try {
    const response = await fetch(`${API_URL}/pages/${pageId}/metadata`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(updatedMetadata)
    });

    if (!response.ok) {
      throw new Error("Failed to update page metadata");
    }

    const updatedPage = await response.json();
    return updatedPage;
  } catch (error) {
    console.error("Error updating page metadata:", error);
    throw error;
  }
};

export const updateItem = async (idToken, pageId, itemId, updatedData) => {
  const response = await fetch(`${API_URL}/pages/${pageId}/items/${itemId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`
    },
    body: JSON.stringify(updatedData)
  });

  if (!response.ok) {
    throw new Error("Failed to update item");
  }

  return response.json();
};

export const deletePage = async (idToken, pageId, fetchPages, setPages) => {
  if (!idToken) {
    console.error("No ID token available");
    return;
  }

  try {
    const response = await fetch(`${API_URL}/pages/${pageId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    });

    if (!response.ok) {
      throw new Error("Failed to delete page");
    }

    await fetchPages(idToken, setPages);
  } catch (error) {
    console.error("Error deleting page:", error);
  }
};

export const createOrUpdateItem = async (idToken, pageId, itemId, itemData) => {
  if (!idToken) {
    console.error("No ID token available");
    throw new Error("Authentication required");
  }

  const id = itemId || Date.now().toString();
  const url = `${API_URL}/pages/${pageId}/items/${id}`;

  try {
    const response = await fetch(url, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(itemData)
    });

    if (!response.ok) {
      throw new Error("Failed to create or update item");
    }

    return await response.json();
  } catch (error) {
    console.error("Error creating or updating item:", error);
    throw error;
  }
};

export const fetchItems = async (idToken, pageId) => {
  try {
    const response = await fetch(`${API_URL}/pages/${pageId}/items`, {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    });

    if (!response.ok) {
      throw new Error("Failed to fetch items");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching items:", error);
    throw error;
  }
};

export const deleteItem = async (idToken, pageId, itemId) => {
  if (!idToken) {
    console.error("No ID token available");
    return;
  }

  try {
    const response = await fetch(`${API_URL}/pages/${pageId}/items/${itemId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    });

    if (!response.ok) {
      throw new Error("Failed to delete item");
    }
  } catch (error) {
    console.error("Error deleting item:", error);
    throw error;
  }
};

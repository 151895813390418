import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
    :root {
        --background--button-primary: rgba(0, 0, 0, .05);
        --background--button-secondary: #ffffff;
        --background--active: #000000;
        --background--inactive: var(--background--button-primary);
        --outline--focus: 2px solid rgba(0, 0, 0, .2);
        --text--primary: #000000;
        --text--label: rgba(0, 0, 0, .5);
        --box-shadow--subtle: 0 2px 4px rgba(0, 0, 0, 0.05), 0 4px 12px rgba(0, 0, 0, 0.08);
        --box-shadow--default: 0 2px 4px rgba(0, 0, 0, 0.05), 0 4px 20px rgba(0, 0, 0, 0.1);
    }
`;

export const theme = {
  backgroundActive: `var(--background--active)`,
  backgroundInactive: `var(--background--inactive)`,
  textDefault: `var(--text--default)`,
  boxShadowSubtle: `var(--box-shadow--subtle)`,
  boxShadowDefault: `var(--box-shadow--default)`,
  textPrimary: `var(--text--primary)`,
  textLabel: `var(--text--label)`
};

import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Composer from "../components/Composer/Composer";
import DebugSection from "../components/Debug";
import {
  fetchItems,
  deleteItem,
  fetchPage,
  updatePageMetadata
} from "../actions/Pages";

import LocationCard from "../components/PreviewCards/LocationCard";
import MovieTvShowCard from "../components/PreviewCards/MovieTvShowCard";
import VideoCard from "../components/PreviewCards/VideoCard";
import BookCard from "../components/PreviewCards/BookCard";
import ArticleCard from "../components/PreviewCards/ArticleCard";
import RecipeCard from "../components/PreviewCards/RecipeCard";
import DefaultCard from "../components/PreviewCards/DefaultCard";

const EditControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
`;

const EditButtons = styled.div`
  display: flex;
  gap: 1rem;
`;

const Items = styled.div`
  padding-bottom: 10rem;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0;
`;

const Buttons = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
`;

const Button = styled.button`
  border: 0;
  outline: none;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  margin: 0;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
`;

const ButtonText = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const ConfirmButton = styled(Button)`
  background-color: #ff4136;
  color: white;
`;

const CancelButton = styled(Button)`
  background-color: #aaaaaa;
  color: white;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const TitleInput = styled.input`
  font-size: 2rem;
  font-weight: bold;
  border: none;
  border-bottom: 2px solid #ccc;
  outline: none;
  width: 100%;
  font-family: "Inter", sans-serif;
  padding: 0.5rem 0;
  margin: 1rem 0;
`;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  border: none;
  outline: none;
  width: 100%;
  padding: 0.5rem 0;
  margin: 1rem 0;
  border-bottom: 2px solid #fff;
`;

const SaveButton = styled(Button)`
  background-color: #2ecc40;
  color: white;
`;

function Page({ idToken, user }) {
  const { pageId } = useParams();
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const bottomRef = useRef(null);
  const [shouldScroll, setShouldScroll] = useState(false);
  const prevItemsRef = useRef();
  const [itemToDelete, setItemToDelete] = useState(null);
  const [showDebug, setShowDebug] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editedTitle, setEditedTitle] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [showCopied, setShowCopied] = useState(false);

  useEffect(() => {
    if (page) {
      document.title = `${page.title}`;
    } else {
      document.title = "Loading...";
    }
  }, [page]);

  const loadItems = async () => {
    try {
      setLoading(true);
      const fetchedPage = await fetchPage(idToken, pageId);
      setPage(fetchedPage);
      const fetchedItems = await fetchItems(idToken, pageId);
      setItems(fetchedItems);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching page and items:", err);
      setError("Failed to load page and items");
      setLoading(false);
    }
  };

  useEffect(() => {
    loadItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shouldScroll) {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
      setShouldScroll(false);
    }
  }, [shouldScroll]);

  useEffect(() => {
    if (prevItemsRef.current) {
      const newDataAdded = items.some((item, index) => {
        const prevItem = prevItemsRef.current[index];
        return (
          prevItem &&
          ((!prevItem.type && item.type) ||
            (!prevItem.basicInfo && item.basicInfo) ||
            (!prevItem.description && item.description))
        );
      });
      if (newDataAdded) {
        setShouldScroll(true);
      }
    }
    prevItemsRef.current = items;
  }, [items]);

  const handleAddItem = (newItem) => {
    setItems((prevItems) => [...prevItems, newItem]);
    setShouldScroll(true);
  };

  const handleUpdateItem = (updatedItem, itemId) => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId ? { ...item, ...updatedItem } : item
      )
    );
    // Scrolling will be handled by the useEffect
  };

  const handleDeleteClick = (itemId) => {
    setItemToDelete(itemId);
  };

  const handleConfirmDelete = async () => {
    if (!idToken) {
      console.error("No ID token available");
      setError("Unable to delete item: No authentication token");
      return;
    }
    try {
      await deleteItem(idToken, pageId, itemToDelete);
      setItems(items.filter((item) => item.id !== itemToDelete));
      setItemToDelete(null);
    } catch (err) {
      console.error("Error deleting item:", err);
      setError("Failed to delete item");
    }
  };

  const handleCancelDelete = () => {
    setItemToDelete(null);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const debugParam = urlParams.get("debug");
    setShowDebug(debugParam === "true");
    const editParam = urlParams.get("edit");
    setIsEditMode(editParam === "true");
  }, []);

  const handleKeyPress = useCallback((event) => {
    if (event.key === "d") {
      setShowDebug((prevShowDebug) => !prevShowDebug);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  const handleEditTitle = () => {
    setIsEditingTitle(true);
    setEditedTitle(page?.title || "");
  };

  const handleSaveTitle = async () => {
    if (!idToken) {
      console.error("No ID token available");
      setError("Unable to save title: No authentication token");
      return;
    }
    try {
      await updatePageMetadata(idToken, pageId, { title: editedTitle });
      setPage({ ...page, title: editedTitle });
      setIsEditingTitle(false);
    } catch (err) {
      console.error("Error saving title:", err);
      setError("Failed to save title");
    }
  };

  // if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const isAuthenticated = !!idToken;
  const isOwner = user?.uid === page?.userId;
  const showEditControls = isAuthenticated && isOwner && isEditMode;

  return (
    <>
      {showEditControls && (
        <EditControls>
          <Link to="/">&larr; back</Link>
          <EditButtons>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(
                  `${process.env.REACT_APP_URL}/page/${pageId}`
                );
                setShowCopied(true);
                setTimeout(() => {
                  setShowCopied(false);
                }, 2000);
              }}
            >
              {showCopied ? (
                <ButtonText>
                  <FontAwesomeIcon icon={fas["faCheck"]} />
                  Copied
                </ButtonText>
              ) : (
                <ButtonText>
                  <FontAwesomeIcon icon={fas["faCopy"]} />
                  Copy Link
                </ButtonText>
              )}
            </Button>
            <Button
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_URL}/page/${pageId}`,
                  "_blank"
                );
              }}
            >
              <ButtonText>
                <FontAwesomeIcon icon={fas["faEye"]} />
                Preview
              </ButtonText>
            </Button>
            <Button
              onClick={() => {
                setShowDebug((prevShowDebug) => !prevShowDebug);
                const newUrl = new URL(window.location.href);
                if (!showDebug) {
                  newUrl.searchParams.set("debug", "true");
                } else {
                  newUrl.searchParams.delete("debug");
                }
                window.history.pushState({}, "", newUrl);
              }}
            >
              <ButtonText>
                <FontAwesomeIcon icon={fas["faBug"]} />
                {showDebug ? "Disable Debug" : "Enable Debug"}
              </ButtonText>
            </Button>
          </EditButtons>
        </EditControls>
      )}
      <TitleContainer>
        {isEditingTitle ? (
          <>
            <TitleInput
              value={editedTitle}
              onChange={(e) => setEditedTitle(e.target.value)}
            />
            <SaveButton onClick={handleSaveTitle}>
              <ButtonText>
                <FontAwesomeIcon icon={fas["faCheck"]} />
                Save
              </ButtonText>
            </SaveButton>
          </>
        ) : (
          <>
            <Title>{page?.title}</Title>
            {showEditControls && (
              <Button onClick={handleEditTitle}>
                <ButtonText>
                  <FontAwesomeIcon icon={fas["faPencil"]} />
                  Edit
                </ButtonText>
              </Button>
            )}
          </>
        )}
      </TitleContainer>
      <Items>
        {items.length > 0 &&
          items.map((item) => (
            <Item key={item.id}>
              {showEditControls && (
                <Buttons>
                  {itemToDelete === item.id ? (
                    <>
                      <ConfirmButton onClick={handleConfirmDelete}>
                        <ButtonText>
                          <FontAwesomeIcon icon={fas["faCheck"]} />
                          Confirm
                        </ButtonText>
                      </ConfirmButton>
                      <CancelButton onClick={handleCancelDelete}>
                        <ButtonText>
                          <FontAwesomeIcon icon={fas["faTimes"]} />
                          Cancel
                        </ButtonText>
                      </CancelButton>
                    </>
                  ) : (
                    <Button onClick={() => handleDeleteClick(item.id)}>
                      <ButtonText>
                        <FontAwesomeIcon icon={fas["faTrash"]} />
                        Delete
                      </ButtonText>
                    </Button>
                  )}
                </Buttons>
              )}
              {!item.type ? (
                <div>Determining type...</div>
              ) : (
                <>
                  {!item.basicInfo ? (
                    <div>Loading basic info...</div>
                  ) : (
                    <>
                      {(() => {
                        switch (item.type) {
                          case "LOCATION":
                            return <LocationCard item={item} />;
                          case "MOVIE_TV_SHOW":
                            return <MovieTvShowCard item={item} />;
                          case "VIDEO":
                            return <VideoCard item={item} />;
                          case "BOOK":
                            return <BookCard item={item} />;
                          case "ARTICLE":
                            return <ArticleCard item={item} />;
                          case "RECIPE":
                            return <RecipeCard item={item} />;
                          case "DEFAULT":
                          default:
                            return <DefaultCard item={item} />;
                        }
                      })()}
                    </>
                  )}
                </>
              )}
              {showDebug && <DebugSection item={item} />}
            </Item>
          ))}
        <div ref={bottomRef} />
      </Items>
      {showEditControls && (
        <Composer
          idToken={idToken}
          pageId={pageId}
          onItemAdded={handleAddItem}
          onItemUpdated={handleUpdateItem}
        />
      )}
    </>
  );
}

export default Page;

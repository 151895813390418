import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCV5Q-Wqtn5Rxly9kLtX4PbWwKo_fmlfZs",
  authDomain: "fcc-page-builder.firebaseapp.com",
  projectId: "fcc-page-builder",
  storageBucket: "fcc-page-builder.appspot.com",
  messagingSenderId: "583802260381",
  appId: "1:583802260381:web:8cfb1eaf49c3b818f27d74",
  measurementId: "G-ERS7LRXY5D"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

const hexToRgba = (hex, alpha = 1) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-radius: 1rem;
  background-color: ${(props) => props.colorLight};
  color: ${(props) => props.colorDark};
  border-color: ${(props) => {
    return hexToRgba(props.colorDark, 0.1);
  }};
  font-family: "DM Sans", sans-serif;

  ::selection {
    background-color: ${(props) => props.colorDark};
    color: ${(props) => props.colorLight};
  }
`;

const LocationContainer = styled.div`
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  justify-content: center;
  gap: 0.25rem;
`;

const CategoryHeading = styled.div`
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  text-align: center;
  margin: 0;
  padding-top: 0.125rem;
  line-height: 120%;
`;

const PlaceNameHeading = styled.h2`
  font-weight: 800;
  font-size: 2rem;
  line-height: 120%;
  text-align: center;
  margin-bottom: 1rem;
  margin: 0;
`;

const Address = styled.p`
  font-size: 0.875rem;
  line-height: 130%;
  padding: 0.125rem 0 0.33rem 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 0.25rem;
  margin: 0;
`;

const StarContainer = styled.div`
  font-size: 0.75rem;
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  align-items: center;
  justify-content: center;
  line-height: 120%;
`;

// const StarNumber = styled.div`
//   font-size: 0.75rem;
//   font-weight: 700;
//   margin: 0;
// `;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  width: 0.75rem;
  height: 0.75rem;
  padding-bottom: 0.133rem;
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 0.75rem 0.75rem 0.75rem;
  gap: 0.75rem;
`;

const ImageContainer = styled.div`
  width: 50%;
  height: 12rem;
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
  background-color: ${(props) => props.colorDark};
  user-select: none;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0px 0px 1px
      ${(props) => {
        return props.colorDark
          ? hexToRgba(props.colorDark, 0.2)
          : "rgba(0, 0, 0, 0.15)";
      }};
    pointer-events: none;
    z-index: 2;
    border-radius: 0.5rem;
    z-index: 2;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
`;

const MapImage = styled(Image)`
  mix-blend-mode: luminosity;
`;

function LocationCard({ item }) {
  const [mapUrl, setMapUrl] = useState("");

  useEffect(() => {
    const fetchMapImage = async () => {
      const address = encodeURIComponent(item.basicInfo.address);
      const state = encodeURIComponent(item.basicInfo.state);
      const zip = encodeURIComponent(item.basicInfo.zip);
      const country = encodeURIComponent(item.basicInfo.country);
      const accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
      const mapboxUrl = `https://api.mapbox.com/search/geocode/v6/forward?address_line1=${address}&region=${state}&postcode=${zip}&country=${country}&access_token=${accessToken}`;

      try {
        const response = await fetch(mapboxUrl);
        const data = await response.json();
        if (data.features && data.features.length > 0) {
          const [longitude, latitude] = data.features[0].geometry.coordinates;
          const staticMapUrl = `https://api.mapbox.com/styles/v1/mapbox/light-v11/static/pin-s+${
            item.basicInfo.colorsFromCategory?.dark.replace("#", "") || "000000"
          }(${longitude},${latitude})/${longitude},${latitude},13,0/400x200@2x?access_token=${accessToken}&attribution=false`;
          setMapUrl(staticMapUrl);
        }
      } catch (error) {
        console.error("Error fetching map data:", error);
      }
    };

    fetchMapImage();
  }, [item.basicInfo]);

  return (
    <Card
      colorLight={item.basicInfo.colorsFromCategory?.light || "white"}
      colorDark={item.basicInfo.colorsFromCategory?.dark || "black"}
    >
      <LocationContainer>
        <CategoryHeading>{item.basicInfo.category}</CategoryHeading>
        <PlaceNameHeading>{item.basicInfo.placeName}</PlaceNameHeading>
        <Address>
          {item.basicInfo.address}, {item.basicInfo.city},{" "}
          {item.basicInfo.state} {item.basicInfo.zip}
        </Address>
        <StarContainer>
          {/* <StarNumber>{item.basicInfo.numStars}</StarNumber> */}
          {[...Array(item.basicInfo.totalStars)].map((_, index) => (
            <StyledFontAwesomeIcon
              key={index}
              icon={
                index < item.basicInfo.numStars ? fas["faStar"] : far["faStar"]
              }
            />
          ))}
        </StarContainer>
      </LocationContainer>

      <ImagesContainer>
        <ImageContainer>
          <Image src={item.basicInfo.image} alt={item.text} />
        </ImageContainer>
        <ImageContainer colorDark={item.basicInfo.colorsFromCategory?.dark}>
          {mapUrl && <MapImage src={mapUrl} alt="Location Map" />}
        </ImageContainer>
      </ImagesContainer>
    </Card>
  );
}

export default LocationCard;
